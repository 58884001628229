<template>
	<div class="flex-1">
		<div class="container">
			<label class="block lg:pt-20.8 lg:pb-18.75 lg:text-4xl text-base p-4 font-medium border-b-2 border-gray-300 lg:pl-12.5">ともだち招待</label>
			<div class="flex flex-col lg:px-12.5 lg:text-lg text-sm lg:mt-60px">
				<div class="border-3 border-asp-blue lg:px-30px lg:pt-35px lg:pb-44px p-4 lg:text-22px text-base">
					<p class="pb-5 font-semibold">{{ to }}</p>
					<p>上記のメールアドレスに招待状を送りました。</p>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
// @ts-check
export default {
	props: { to: String }
};
</script>
