// @ts-check
/* eslint-disable camelcase */

import firebase from 'firebase/app';
import 'firebase/firestore';
import { convertDocument, getFieldValue } from './utils';

/** @type {firebase.firestore.CollectionReference<Omit<t.InvitationHistory,'docId'>>} */
const invitationHistoryCol = (firebase.firestore().collection('d_invitation_history'));

export default {
	/**  @param {t.UserId} userId */
	getByUserId: async userId => {
		const result = await invitationHistoryCol.where('user_id', '==', userId).get();
		return result.docs.map(convertDocument);
	},

	/** @param {t.InvitationHistoryId} docId */
	deleteByDocid: async docId => {
		return invitationHistoryCol.doc(docId).delete();
	},

	/** @param {Pick<t.InvitationHistory,'user_id'|'send_to'>} data */
	create(data) {
		return invitationHistoryCol.add({
			user_id: data.user_id,
			send_to: data.send_to,
			created_at: getFieldValue().serverTimestamp(),
			updated_at: null
		});
	}
};
