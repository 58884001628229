<script>
// @ts-check
import { reactive } from 'vue';
import invitationApi from '@/apis/invitationApi';
import invitationHistoryApi from '@/apis/invitationHistoryApi';
import { useField, useForm } from 'vee-validate';
import { object, string } from 'yup';
import InvitationConfirm from '@/views/invitation/InvitationConfirm.vue';
import InvitationComplete from '@/views/invitation/InvitationComplete.vue';
import { globalState } from '@/stores/global';
import { useQuery } from 'vue-query';
import { f } from '@/services/utils';

function useInviteFriendForm() {
	const validationSchema = object({
		mail_address: string()
			.required('メールアドレスを入力してください。')
			.email('正しいメールアドレスを入力してください。')
	});
	const form = useForm({ validationSchema, initialValues: { mail_address: '' } });
	useField('mail_address');

	return form;
}

export const useInvitationLimit = () => {
	const { user } = globalState;
	const getInvitationLimit = async () => {
		const [maxTimes, usedHistories] = await Promise.all([invitationApi.getMaxInvitationLimit(), invitationHistoryApi.getByUserId(user.id)]);
		return Math.max(maxTimes - usedHistories.length, 0);
	};

	const query = useQuery('invitation_limit_max', getInvitationLimit, { initialData: 0 });
	return query;
};

export default {
	components: { InvitationConfirm, InvitationComplete },
	setup() {
		const state = reactive({
			/** @type {t.pages.userInvitations.DefaultState['renderComponent']} */
			renderComponent: ('invitation')
		});
		const form = useInviteFriendForm();
		const { data: inviteLimit } = useInvitationLimit();

		return {
			state,
			form,
			inviteLimit,
			f
		};
	},

	methods: {
		async inviteFriendAction() {
			if (!this.form.meta.value.valid) return;
			this.state.renderComponent = 'invitation-confirm';
		},
		/** @param {t.pages.userInvitations.DefaultState['renderComponent']} page */
		setRenderComponent(page) {
			this.state.renderComponent = page;
		}
	}
};
</script>

<template>
	<div v-if="state.renderComponent === 'invitation'" class="flex-1">
		<div class="container">
			<label class="block lg:pt-20.8 lg:pb-18.75 lg:text-3xl text-base p-4 font-medium border-b-2 border-gray-300 lg:pl-12.5">ともだち招待（保護者の方と一緒に入力してね）</label>
			<div class="flex flex-col lg:px-12.5 p-4">
				<span class="lg:mt-62px lg:mb-55px lg:text-22px  text-base">
					おともだちを招待できる回数:
					<label class="pl-23px">{{ inviteLimit }}回</label>
				</span>
				<p v-if="inviteLimit" class="mb-28px lg:text-22px text-base">
					招待する友達のメールアドレスを入力してください。
				</p>
				<p v-if="inviteLimit" class="mb-28px lg:text-22px text-base">
					※入力されたメールアドレスに間違いがないようご注意下さい。
				</p>
				<input v-if="inviteLimit" v-model="form.values.mail_address" type="text" class="px-3 border border-black mb-2 h-42px focus:outline-none" />
				<div class="text-red-500 mb-8">{{ form.errors.value.mail_address }}</div>
				<div class="flex justify-center">
					<button v-if="inviteLimit" class="lg:px-24 lg:py-4 px-10 py-2 text-base text-white rounded-full lg:text-22px bg-asp-orange focus:outline-none" @click="inviteFriendAction">
						次へ
					</button>
				</div>
			</div>
		</div>
	</div>
	<invitation-confirm v-if="state.renderComponent === 'invitation-confirm'" :to="f(form.values.mail_address)" @setRenderComponent="setRenderComponent"></invitation-confirm>
	<invitation-complete v-if="state.renderComponent === 'invitation-complete'" :to="f(form.values.mail_address)"></invitation-complete>
</template>
