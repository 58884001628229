// @ts-check
/* eslint-disable camelcase */
import firebase from 'firebase/app';
import 'firebase/firestore';
import { convertDocument } from './utils';

/** @type {firebase.firestore.CollectionReference<t.InvitationCode>} */
const invitationCol = (firebase.firestore().collection('d_invitation_code'));

/** @type {firebase.firestore.CollectionReference<t.M_InvitationLimit>} */
const invitationLimitCol = (firebase.firestore().collection('m_invitation_limit'));

export default {
	/** @param {string} invitation_code */
	async getByCode(invitation_code) {
		const result = await invitationCol.where('invitation_code', '==', invitation_code).get();
		return result.docs.map(convertDocument)[0];
	},

	/** @param {Partial<t.InvitationCode>} param */
	generateCode: async ({ invitation_code, issue_type = 1, distribute_flag = 1, created_at = firebase.firestore.FieldValue.serverTimestamp() }) => {
		return invitationCol.add({
			invitation_code,
			issue_type,
			distribute_flag,
			created_at
		});
	},

	/** @param {t.InvitationCode['docId']} docId */
	deleteByDocid: async docId => {
		const result = await invitationCol.doc(docId).delete();
		return result;
	},

	getMaxInvitationLimit: async () => {
		const result = await invitationLimitCol.get();
		return result.docs.map(convertDocument)[0]?.invitation_limit || 0;
	}
};
