<script>
// @ts-check
import { reactive } from 'vue';
import invitationApi from '@/apis/invitationApi';
import invitationHistoryApi from '@/apis/invitationHistoryApi';
import mailApi from '@/apis/mailApi';
import { fDefined, getRandomCode } from '@/services/utils';
import { globalState } from '@/stores/global';
import { queryClient } from '@/main';
import { useInvitationLimit } from './Invitation.vue';

const URL = process.env.VUE_APP_HOSTING_URL;

export default {
	props: {
		to: {
			type: String,
			required: true
		}
	},
	/** @type {{ setRenderComponent: (page: t.pages.userInvitations.DefaultState['renderComponent']) => void }}  */
	// @ts-ignore
	emits: ['setRenderComponent'],
	setup() {
		const state = reactive({
			invitationCode: '',
			sendEmailActionSubmiting: false
		});

		const createInvitationCode = async () => {
			try {
				let invitationCode = '';
				let checkGenneratingCode = true;
				while (checkGenneratingCode) {
					invitationCode = fDefined(getRandomCode());
					// eslint-disable-next-line no-await-in-loop
					checkGenneratingCode = !!(await invitationApi.getByCode(invitationCode));
				}

				state.invitationCode = invitationCode;
			} catch (error) {
				console.log(error);
			}
		};
		createInvitationCode();

		return {
			signedInUser: globalState.user,
			state,
			URL
		};
	},

	methods: {
		async sendEmailAction() {
			// FIXME khi bấm vào nút mời bạn nhiều lần thì gửi nhiều mail
			if (this.state.sendEmailActionSubmiting) return;
			try {
				this.state.sendEmailActionSubmiting = true;
				/** @type {{ emailTemplate: HTMLDivElement }} */
				const $refs = (this.$refs);
				const html = $refs.emailTemplate.outerHTML;

				await Promise.all([
					invitationApi.generateCode({
						invitation_code: fDefined(this.state.invitationCode),
						issue_type: 1
					}),
					mailApi.sendInvitation({
						to: this.to,
						message: {
							subject: '【あすたーとぴーす】より招待状が届きました！',
							html
						}
					}),
					invitationHistoryApi.create({
						user_id: fDefined(this.signedInUser.id),
						send_to: this.to
					})
				]);

				queryClient.setQueryData('invitation_limit_max', (/** @type {FetcherReturn<typeof useInvitationLimit>} */ value) => {
					return Math.max(fDefined(value) - 1, 0);
				});
				this.$emit('setRenderComponent', 'invitation-complete');
			} catch (error) {
				console.log(error);
			} finally {
				this.state.sendEmailActionSubmiting = false;
			}
		}
	}
};
</script>

<template>
	<div class="flex-1">
		<div class="container">
			<label class="block pt-20.8 pb-18.75 text-4xl font-medium border-b-2 border-gray-300 pl-12.5">ともだち招待</label>
			<div id="test" class="flex flex-col px-12.5 text-lg">
				<p class="font-bold mt-35px mb-20px">招待状メール件名</p>
				<p class="border-2 border-gray-300 px-10px py-8px mb-35px bg-asp-f9f9f9">
					【あすたーとぴーす】より招待状が届きました！
				</p>
				<p class="font-bold mb-20px">招待状メール本文</p>
				<div ref="emailTemplate" class="px-5 border-2 border-gray-300 mb-32px focus:outline-none pt-8px pb-30px bg-asp-f9f9f9 ">
					<p class="mb-12px">{{ signedInUser.nickname }} さんから、絵でつながるSNS【あすたーとぴーす】への招待状が届いています。</p>
					<p class="mb-12px">
						ともだちと一緒にたくさん絵を投稿して、みんなで一緒にパズルを完成させましょう！
					</p>
					<p class="mb-12px">
						下のリンクからアクセスして、招待コードを入力して登録して下さい！
					</p>
					<p class="mb-12px">
						【あすたーとぴーす】登録はあすたーとぴーこちら：
						<a class="text-blue-700" :href="URL" target="_blank">
							{{ URL }}
						</a>
					</p>
					<p>招待コード： {{ state.invitationCode }}</p>
					<br />
					<p>一般社団法人 A Start Piece Lab.</p>
					<a href="https://astartpiece.com/" class="text-blue-500" target="_blank">https://astartpiece.com/</a>
				</div>
				<div class="flex justify-center mb-30px">
					<button class="px-24 py-4 text-2.75xl text-white rounded-full bg-asp-orange focus:outline-none" @click="sendEmailAction">
						ともだちを招待する
					</button>
				</div>
			</div>
		</div>
	</div>
</template>
